import React from "react"

interface IProps {
  children: React.ReactNode
}

/**
 * Wraps a child into a div with sponsor styles.
 *
 * Used in customer instance "SINN Sachsen".
 */
const SponsorComponent: React.FC<IProps> = (props: IProps) => {
  return <div className="sponsors__item">{props.children}</div>
}

export default SponsorComponent
