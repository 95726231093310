import { useRouter } from "next/router"
import useTranslation from "next-translate/useTranslation"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IUORFromEntityType, IProgram, IProject, IUser, IUserProjectRole, MembershipRole } from "@api/schema"
import { INNER_TEAM_ROLES } from "@basics/pageAccess"
import ErrorPage from "@components/ErrorPage"
import SpinnerPage from "@components/SpinnerPage"
import { loadCurrentProcessAction, ProcessActionTypes } from "@redux/actions/processes"
import { loadModelBySlugOrIdAction } from "@redux/helper/actions"
import { IRequestState } from "@redux/helper/state"
import { AppState } from "@redux/reducer"
import { selectCurrentProcess, selectProjectBySlugOrId, selectSingleEntityUsecaseState } from "@redux/reducer/data"
import { EntityType } from "@redux/reduxTypes"

import { useCurrentUser } from "./hooks/useCurrentUser"
import { useEntity } from "./hooks/useEntity"
import { getStringFromQuery } from "./routesHelper"
import { getUORBySlugOrIdFromUserObjectRoles } from "./userObjectRolesHelper"

/**
 * structure of what the useInitialDataForProjectPage hook returns
 */
interface IProjectPageInitialData {
  slugOrId: string
  project: IProject
  projectRequest: IRequestState
  projectErrorOrSpinnerPage: JSX.Element
}

/**
 * structure of what the useInitialDataForInternalProjectPage hook returns
 */
interface IInternalProjectPageInitialData extends IProjectPageInitialData {
  currentUser: IUser
  membershipUOR: IUserProjectRole
  hasEditorRights: boolean
  isCoordinator: boolean
  isPlanner: boolean
  isObserver: boolean
}

/**
 * structure of what the useCurrentProgram hook returns
 */
interface IUseCurrentProgramRequestData {
  currentProgram: IProgram
  programRequest: IRequestState
  programErrorOrSpinnerPage: JSX.Element
}


/**
 * Hook for all external project slug pages.
 *
 * @returns all relevant elements for a public project page
 */
export const useInitialDataForProjectPage = (): IProjectPageInitialData => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { t } = useTranslation("common")

  // slug could also be the ID
  const slugOrId = getStringFromQuery(router, "slug")

  // useEntity() cannot be used, because it uses id as param but not the slug
  // get the (possibly loaded) project from the state
  const projectFromState = useSelector((state: AppState) => selectProjectBySlugOrId(state, slugOrId))
  const projectRequest = useSelector((state: AppState) => selectSingleEntityUsecaseState(state, EntityType.Project, slugOrId))

  useEffect(() => {
    // trigger the loading
    // if there is a slugOrId but no detailed projectFromState
    // if loading has not happened yet
    // if loading is not already running
    if (slugOrId && !projectFromState?.detailResult
      && (!projectRequest || projectRequest.loaded === false)
      && !projectRequest?.isLoading
    ) {
      dispatch(loadModelBySlugOrIdAction(EntityType.Project, slugOrId))
    }
  }, [slugOrId, JSON.stringify(projectRequest), projectFromState?.id])

  // #region error page creation
  let projectErrorOrSpinnerPage: JSX.Element = null

  // order of the if-else-structure is important!
  // "hard failure" first, softer failure later, loading response latest
  if (!slugOrId || (projectRequest?.loaded && !projectFromState)) {
    projectErrorOrSpinnerPage = <ErrorPage statusCode={404} error={"failure.object.notFound"} />
  } else if (projectRequest?.loadingError) {
    projectErrorOrSpinnerPage = <ErrorPage statusCode={404} error={projectRequest.loadingError} />
  } else if (projectFromState?.locked) {
    projectErrorOrSpinnerPage = <ErrorPage statusCode={423} error={"failure.object.locked"} />
  } else if (!projectFromState && (!projectRequest || projectRequest?.isLoading)) {
    // if there is not projectRequest yet (= project cannot be loaded) or it is loading right now: show spinner
    projectErrorOrSpinnerPage = <SpinnerPage title={projectFromState?.name} description={t("page.loading.title")} />
  }
  // #endregion error page creation

  return { slugOrId, project: projectFromState, projectRequest, projectErrorOrSpinnerPage }
}

/**
 * Hook for all internal project member pages.
 *
 * @returns all relevant elements for an internal project page
 */
export const useInitialDataForInternalProjectPage = (): IInternalProjectPageInitialData => {
  const router = useRouter()
  const { t } = useTranslation("common")

  // slug could also be the ID
  const slugOrId = getStringFromQuery(router, "slug")
  // get currentUser but do not load it if there is no slug to avoid unnessecary API calls
  const { currentUser, userObjectRoles } = useCurrentUser(!!slugOrId)
  const roleOfProjectWithSlug = getUORBySlugOrIdFromUserObjectRoles(userObjectRoles, slugOrId, EntityType.Project) as IUORFromEntityType<EntityType.Project>
  const projectFromUOR = roleOfProjectWithSlug?.object

  const { entity: detailedProject, request: projectRequest } = useEntity(
    EntityType.Project,
    projectFromUOR?.id,
    { doNotLoad: !projectFromUOR || projectFromUOR.locked }
  )

  const hasEditorRights = INNER_TEAM_ROLES.includes(roleOfProjectWithSlug?.["@type"])
  const isCoordinator = roleOfProjectWithSlug?.["@type"] === MembershipRole.Coordinator
  const isPlanner = roleOfProjectWithSlug?.["@type"] === MembershipRole.Planner
  const isObserver = roleOfProjectWithSlug?.["@type"] === MembershipRole.Observer

  // #region error page creation
  let projectErrorOrSpinnerPage: JSX.Element = null

  // order of the if-else-structure is important!
  // "hard failure" first, softer failure later, loading response latest
  if (!slugOrId || (projectRequest?.loaded && !detailedProject)) {
    projectErrorOrSpinnerPage = <ErrorPage statusCode={404} error={"failure.object.notFound"} />
  } else if (currentUser && !projectFromUOR) {
    projectErrorOrSpinnerPage = <ErrorPage statusCode={403} error={"failure.object.notFoundOrNoPermission"} />
  } else if (projectRequest?.loadingError) {
    projectErrorOrSpinnerPage = <ErrorPage statusCode={404} error={projectRequest.loadingError} />
  } else if (projectFromUOR?.locked) {
    projectErrorOrSpinnerPage = <ErrorPage statusCode={423} error={"failure.object.locked"} />
  } else if (!projectRequest || projectRequest?.isLoading) {
    // if there is not projectRequest yet (= detailed project cannot be loaded) or it is loading right now: show spinner
    projectErrorOrSpinnerPage = <SpinnerPage title={projectFromUOR?.name} description={t("page.loading.title")} />
  }
  // #endregion error page creation

  return { slugOrId, currentUser, project: detailedProject, membershipUOR: roleOfProjectWithSlug, hasEditorRights, isCoordinator, isPlanner, isObserver, projectRequest, projectErrorOrSpinnerPage }
}


/**
 * Hook to use the current program.
 * Loads the program if it is not present yet.
 *
 * NOTE: this version of useCurrentProgram does not check on detailResult of the program,
 * so a loading of a collection overwrites already the loaded detailResult state
 *
 * @param loadIfNotPresent Should the hook load the current program if it is not in state?
 * To avoid unnessecary loads, e.g. if no project slug is given. default: true
 * @returns current program, program request and error/spinner page to be handled/used/returned by the caller
 */
export const useCurrentProgram = (loadIfNotPresent = true): IUseCurrentProgramRequestData => {
  const { t } = useTranslation("common")
  const dispatch = useDispatch()

  const programRequest = useSelector((state: AppState) =>
    selectSingleEntityUsecaseState(state, EntityType.Program, ProcessActionTypes.LoadCurrentProcess)
  )
  const currentProgram = useSelector(selectCurrentProcess)

  useEffect(() => {
    if (!currentProgram
      && (!programRequest || programRequest?.loaded === false)
      && !programRequest?.isLoading && loadIfNotPresent
    ) {
      dispatch(loadCurrentProcessAction())
    }
  }, [JSON.stringify(programRequest), currentProgram, loadIfNotPresent])

  let programErrorOrSpinnerPage: JSX.Element = null
  // if process could not be loaded and an error returned, display the error
  if (programRequest?.loadingError) {
    programErrorOrSpinnerPage = <ErrorPage statusCode={404} error={programRequest?.loadingError} />
  } else if (programRequest?.loaded && !currentProgram) {
    // if the process could not be loaded, no error returned, but null returned, display a message like no process on the platform
    programErrorOrSpinnerPage = <ErrorPage statusCode={404} error="failure.object.notFound" />
  } else if (programRequest?.isLoading) {
    // if there is no processRequest yet or it is loading right now: show spinner
    programErrorOrSpinnerPage = <SpinnerPage title={t("page.loading.title")} description={t("page.loading.title")} />
  }

  return { currentProgram, programRequest, programErrorOrSpinnerPage }
}